import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "思考とデザインスキル  〜メドレー TechLunch〜",
  "date": "2017-09-14T04:20:31.000Z",
  "slug": "entry/2017/09/14/132031",
  "tags": ["medley"],
  "hero": "./2017_09_14.png",
  "heroAlt": "design skill"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`はじめまして！最近みるみる太りだしてはいるものの、まだ機は熟していないとダイエットの時期をぐっと堪えている開発本部イケメン担当のデザイナー・小山です。`}</p>
    <p>{`メドレーでは TechLunch という社内勉強会を実施しているのですが、`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/03/160000"
      }}>{`前田`}</a>{`に引き続き私も発表する機会をいただきましたので、その内容を紹介させていただきます。テーマは「思考とデザインスキル」です。発表資料は記事の最後をご覧ください。`}</p>
    <h1>{`テーマに入るまえに…`}</h1>
    <p>{`みなさん『黄金比』ってご存知ですか？`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170913/20170913121842.png",
        "alt": "f:id:medley_inc:20170913121842p:plain",
        "title": "f:id:medley_inc:20170913121842p:plain"
      }}></img></p>
    <p>{`黄金比は美しいとされる物の形に共通してみられる比率で、古くから絵画や彫刻、建築などに使われています。デザイナーであれば、一度以上は使ったことあるのではないでしょうか？私もデザイナーなので何度も使っています。`}</p>
    <p>{`ただ使ってはいるものの、なぜ美しくなるのか上手く説明ができません。当たり前のように世の中に広まっていて『困ったときの黄金比！』といった安易な思考で使っています。三十路を軽く超えたのでそれではいけないと思い、すこし調べてみました。`}</p>
    <p>{`そもそも黄金比はエウクレイデスというユーグリッド幾何学を体系化した数学者が見つけた比率なのですが、そのとき彼は『黄金比＝美しい』とは明言していないそうです。自然物や人工物の形には一定の比率で成り立っていると考え、その比率に黄金比と名付けだけとのこと。`}</p>
    <p>{`その後、その使いやすさから至るところで活用され、その比率に親しみが芽生え巷で受け入れられるようになりました。`}</p>
    <p>{`認知心理学では、それを『`}<strong parentName="p">{`単純接触効果`}</strong>{`』と呼びます。たくさん触れるうちに親しみが沸く機能が人にはもともと備わっていて、黄金比をつかったものが美しく見えるのもその影響ではないか？と言われています。この説を聞いた時、基礎的なのに原理が曖昧なデザイナーのスキルの輪郭がすこしだけハッキリしてきました。`}</p>
    <p>{`今回の TechLunch では、曖昧だったデザインのスキルを人の思考や心理現象という視点から捉え直してみると、新しい発見があるのかも？と考え、『思考とデザインスキル』のテーマを選びました。`}</p>
    <h1>{`『早い思考』と『遅い思考』`}</h1>
    <p>{`まず最初に人の思考がどういう構造になっているか整理したいと思います。`}</p>
    <p>{`＊思考の捉え方には幅があるため、今回は Wikipedia で【思考】の狭義にあたる『情報処理』の内容になります。`}</p>
    <p>{`ここで 3 つの質問を左から順に答えてください。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170913/20170913122003.png",
        "alt": "f:id:medley_inc:20170913122003p:plain",
        "title": "f:id:medley_inc:20170913122003p:plain"
      }}></img></p>
    <p>{`大抵の人であれば 2 問目までは瞬時に答えが出たと思います。3 問目はどうでしょうか？`}</p>
    <p>{`3 問目は他よりも時間がかかったかと思います。情報を処理するときの思考には 1 問目と 2 問目のように瞬時に答えれるのは『`}<strong parentName="p">{`早い思考`}</strong>{`』、3 問目のように少し時間は必要な『`}<strong parentName="p">{`遅い思考`}</strong>{`』の 2 つがあります。`}</p>
    <p>{`行動経済学では、この早い思考を『`}<strong parentName="p">{`システム 1`}</strong>{`』、遅い思考を『`}<strong parentName="p">{`システム 2`}</strong>{`』と呼んでいます。`}</p>
    <h1>{`システム 1 は、自動的に直感で動く`}</h1>
    <p>{`では次にこちらをご覧ください。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170913/20170913122043.png",
        "alt": "f:id:medley_inc:20170913122043p:plain",
        "title": "f:id:medley_inc:20170913122043p:plain"
      }}></img></p>
    <p>{`どちらの直線が長いでしょうか？答えはどちらも同じです。この問題をご存知の方でも見た瞬間は A が長く見えるのではないでしょうか？`}</p>
    <p>{`ではこちらではどうでしょうか？`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170913/20170913122101.png",
        "alt": "f:id:medley_inc:20170913122101p:plain",
        "title": "f:id:medley_inc:20170913122101p:plain"
      }}></img></p>
    <p>{`こちらの文字をみたときに、海水浴していて溺れている、もしくはそれに近いシーンを思い浮かべてないでしょうか？　この二語のあいだには『りんご　あまい』というような直接の相関はありません。それにもかかわらず出来れば避けたくなるようなことでも無意識に関連づけられ、シーンが思い浮かんだはずです。`}</p>
    <p>{`システム 1 には、さきほどの直線の長さのように間違っていたとしても『`}<strong parentName="p">{`見たまま`}</strong>{`』を認識する機能と 2 つの文字から『`}<strong parentName="p">{`関連づけ`}</strong>{`』をおこないストーリーを組み立てる機能があります。そのどちらもが自分の意識とは関係なく自動で、しかも強力に働いています。`}</p>
    <h1>{`システム 2 は、手動で論理的に動く`}</h1>
    <p>{`最初の二桁の掛け算を思い出してください。日本で算数を学んだ人であれば、二桁の掛け算のとき『考える』段階に移ると思います。これがシステム 2 のスイッチです。システム 1 は常時スイッチが入っていてほぼ自動で答えを出しますが、システム 2 は意識的に『考える』というステップを踏まないと動きません。システム 1 は自動ですが、システム 2 は手動です。`}</p>
    <p>{`手動のため手間かかりますが、システム 1 にはない用心深さと慎重さがあります。こちらの問題をご覧ください。`}</p>
    <blockquote>
      <p parentName="blockquote">{`『`}<strong parentName="p">{`バットとボールで 110 円、バットはボールより 100 円高い、ボールの値段は？`}</strong>{`』`}</p>
    </blockquote>
    <p>{`即答で答えた人は 10 円と答える方が多いようです。こういう問題にはシステム 2 がうってつけで、論理的かつ正確に答えを出そうとします。答えは 5 円です。なかには頭の回転が早く即答できる人がいらっしゃることだと思います。そんな方にはこちらの問題を答えていだだきましょう。`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`3 日前から食べた夕飯の献立を口に出して発表しながら、『26x673』『245x287』『346x4546』の 3 問を 90 秒以内に解答してください。`}</strong></p>
    </blockquote>
    <p>{`いかがでしょうか？システム 2 は手動でうごき論理的で正確に答え出そうとしますが、複雑すぎる演算やマルチタスクにめっぽう弱くスタミナもありません。あきらめて電卓を叩いた方は、システム 2 がギブアップしたということかもしれません。`}</p>
    <p>{`システム 1 と 2 の特徴は以下。どちらも良いところとそうでないところがあります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170913/20170913122233.png",
        "alt": "f:id:medley_inc:20170913122233p:plain",
        "title": "f:id:medley_inc:20170913122233p:plain"
      }}></img></p>
    <h1>{`デザインを再構築`}</h1>
    <p>{`これらシステム 1&2 の特性を踏まえた上で簡単なニュース記事のタイポグラフィを整理してみました。`}</p>
    <p>{`発表資料の 58 ページからご覧ください。`}</p>
    <iframe id="talk_frame_406667" style={{
      "border": "0",
      "padding": "0",
      "margin": "0",
      "background": "transparent"
    }} src="https://speakerdeck.com/player/b39b3240b99247979b07297b660b9c9f" width="710" height="596" frameBorder="0" allowFullScreen="true"></iframe>
    <cite className="hatena-citation"><a href="https://speakerdeck.com/medley/si-kao-todezainsukiru">speakerdeck.com</a></cite>
    <p>{`こと細かく情報をシステム 1&2 のフィルターを通すことで、タイトルやリード、本文の行間、文字サイズ、それらがシステム 2 が情報を理解しやすくするための１つ機能として捉えることができます。当たり前に使っていたスキルや機能を別の視点で捉えることで違った深い意味を見出せるようになり、大きな発見につながりました。`}</p>
    <p>{`今回はタイポグラフィだったのでシステム 2 寄りのものでしたが、ビジュアルアイデンティティが強く抽象的なデザインは、意図的にシステム 2 を封じ込めシステム 1 の直感性を利用して内容を理解してもらうこともできます。`}</p>
    <p>{`システム 1&2 の 2 つの特性を踏まえて使いこなすことで、スキルの深い理解はもちろんですが、感覚とは違うデザインの伝え方の新しいヒントにもなりそうです。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`YouTube や Instagram、LINE はどちらかというと直感性を促すシステム 1 が活躍するアプリです。それだけでなく身の回りにあるサービス全体がその傾向という印象をうけます。システム 1 は普段からスイッチが入っているため、比較的に簡易なステップで働きかけることができます。`}</p>
    <p>{`一方でシステム 2 は複雑な演算には耐えれず、しっかりとケアしながらでないと十分な運用ができません。ただケアをしっかりすると恩恵も大きいといえます。`}</p>
    <p>{`金融、教育、雇用、そして医療と、20 年前とは比較にならないほどインターネットは人生の節目に深く関わるようになったからです。人生に関わる選択をネットで行うとき、`}<strong parentName="p">{`直感だけでなくシステム 2 を働かせて熟考し納得のいく決断を行うことが、よりユーザーの利益につながる`}</strong>{`と私は考えています。`}</p>
    <p>{`メドレーで提供しているサービスはユーザーの人生に少なからず関わる性質をもっています。すぐに結論づけしてもらうより、ユーザーにとって正しいと思える判断ができるように、`}<strong parentName="p">{`システム 2 をうまく働かせることができる環境をデザイナーとしてつくっていきたい`}</strong>{`と思います。`}</p>
    <h1>{`おまけ`}</h1>
    <p>{`今回のテックランチで参考にさせていただいた書籍は以下のものになります。`}</p>
    <p>{`『`}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/gp/product/B0716S2Z29/ref=bpbB0716S2Z29?pf_rd_m=AN1VRQENFRJN5&pf_rd_s=product-alert&pf_rd_r=BZN853DD5XN3M0CSRSAQ&pf_rd_t=201&pf_rd_p=458809509&pf_rd_i=4150504105"
      }}>{`ファスト＆スロー（上・下）`}</a>{`』ダニエル・カーネマン`}</p>
    <p>{`『`}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/dp/4150503915"
      }}>{`予想通りに不合理`}</a>{`』ダン・アリエリ`}</p>
    <p>{`『`}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/dp/4163736700/"
      }}>{`錯覚の科学`}</a>{`』クリストファー・チャブルス＆ダニエル・シモンズ`}</p>
    <p>{`『`}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/dp/B00VJF2044/"
      }}>{`UI デザインの心理学`}</a>{`』ジェフ・ジョンソン`}</p>
    <p>{`今回のお話は、本当に本当に表面の部分になります。流し読みでも参考になるので手に取って見てください。またこういうことに興味がわいたデザイナーさん・エンジニアさん、是非是非メドレーに遊びに来てください！（絶賛募集中です！）`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、ジョブメドレーだけでなく、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」やオンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」、口コミで探せる介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」などのプロダクトも提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=https://www.medley.jp/recruit/creative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>
    <p>{`今後ともメドレーを、よろしくお願いいたします！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      